<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    :show-footer="formStatus !== 'detail'"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    >
      <UploadImg slot="photoIds" slot-scope="{item}" :limit="3" :upload-lists="photoUploadLists" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" :disabled="formStatus === 'detail'" />
      <CustomMap slot="customMap" ref="CustomMap" :lat="formModel.latitude" :lng="formModel.longitude" :disabled="formStatus === 'detail'" @callback="formItem.customMap.callback" />
      <div slot="address">
        <el-input v-model="formModel.address" type="text" :maxlength="255" placeholder="请输入详细地址或在地图中选择" clearable :disabled="formStatus === 'detail'" />
        <div style="display: flex; margin-top: 10px;">
          <el-input v-model="formModel.latitude" type="text" placeholder="请在地图中选择位置以获取纬度" disabled style="margin-right: 5px;" />
          <el-input v-model="formModel.longitude" type="text" placeholder="请在地图中选择位置以获取经度" disabled style="margin-left: 5px;" />
        </div>
      </div>
    </CustomForm>
    <template slot="centerButton" slot-scope="{loading}">
      <el-button type="info" :loading="loading" @click="handleSaveDraft">保存为草稿</el-button>
    </template>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import RegionForm from '@/components/RegionForm'
import UploadImg from '@/components/UploadImg'
import CustomMap from '@/components/CustomMap/index'
export default {
  name: 'Form',
  components: { CustomMap, UploadImg, RegionForm, CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formId: '',
      formStatus: '',
      topicsArray: [],
      photoUploadLists: [],
      formModel: {
        createType: 1,
        photoIds: '',
        content: '',
        url: '',
        topics: [],
        tenant: [],
        address: '',
        latitude: 0,
        longitude: 0
      }
    }
  },
  computed: {
    formItem() {
      return {
        'topics': {
          'elColSpan': 24,
          'component': 'CustomFormSelect',
          'label': '话题',
          'data': this.topicsArray,
          'multiple': true,
          'filterable': true,
          'disabled': this.formStatus === 'detail'
        },
        'photoIds': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'photoIds',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'photoIds', data.join(','))
            } else {
              this.$set(this.formModel, 'photoIds', '')
            }
          }
        },
        'content': {
          'elColSpan': 24,
          'component': 'CustomFormTinymce',
          'label': '内容',
          'disabled': this.formStatus === 'detail'
        },
        'url': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': 'URL',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'tenant': {
          'elColSpan': 24,
          'label': '服务区域',
          'slot': 'tenant'
        },
        'address': {
          'elColSpan': 24,
          'label': '地址信息',
          'slot': 'address'
        },
        'customMap': {
          'elColSpan': 24,
          'slot': 'customMap',
          'callback': data => {
            this.formModel.address = data.poiaddress
            this.formModel.latitude = data.latlng.lat
            this.formModel.longitude = data.latlng.lng
          }
        }
      }
    },
    formRules() {
      return {
        photoIds: [
          { required: true, message: '图片不能为空', trigger: ['blur', 'change'] }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: ['blur', 'change'] }
        ],
        url: [
          { type: 'url', message: 'URL格式不正确', trigger: ['blur', 'change'] }
        ],
        tenant: [
          { required: true, message: '服务区域不能为空', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '地址信息不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (!this.formModel.latitude || !this.formModel.longitude) {
                callback(new Error('坐标信息不能为空'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['downloadFile', 'neighborhoodTopicsPublicPageList', 'neighborhoodPublicDetail', 'neighborhoodAdd', 'neighborhoodUpdate']),
    handleAdd() {
      this.init('add')
    },
    handleEdit(id) {
      this.init('edit', id)
    },
    handleDetail(id) {
      this.init('detail', id)
    },
    init(formStatus, id = '') {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      // 获取所有话题
      this.neighborhoodTopicsPublicPageList({ status: 1 }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.topicsArray = res.data.map(item => {
          return {
            label: item.title,
            value: item.title
          }
        })
      }).catch(() => {})
      this.formId = id
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        case 'detail':
          this.title = '查看'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.initData().then(() => {
        this.$nextTick(() => {
          this.$refs.RegionForm.initRegion()
          this.$refs.CustomMap.init()
        })
        this.$refs.CustomForm.$refs.form.clearValidate()
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.neighborhoodPublicDetail({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }

            if (res.data.photoIds && res.data.photos) {
              const photoIds = res.data.photoIds.split(',')
              const photos = res.data.photos.split(',')
              photoIds.forEach((item, index) => {
                if (index in photos) {
                  this.photoUploadLists.push({
                    'id': item,
                    'url': photos[index]
                  })
                }
              })
            }

            this.formModel.id = this.formId
            this.formModel.createType = res.data.createType
            this.formModel.topics = res.data.topics.split(',')
            this.formModel.photoIds = res.data.photoIds
            this.formModel.content = res.data.content
            this.formModel.url = res.data.url
            this.formModel.tenant = res.data.tenant.split(',').slice(2)
            this.formModel.latitude = res.data.latitude
            this.formModel.longitude = res.data.longitude
            this.formModel.address = res.data.address
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          let data = sessionStorage.getItem('NEIGHBORHOOD')
          if (data) {
            data = JSON.parse(data)
            this.formModel = data.formModel
            this.getFileDetail(data.formModel.photoIds)
          }
          resolve()
        }
      })
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          params.topics = params.topics.join(',')
          params.tenant = `1,101,${params.tenant.join(',')}`

          switch (this.formStatus) {
            case 'add':
              delete params.id
              this.neighborhoodAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                sessionStorage.removeItem('NEIGHBORHOOD')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.neighborhoodUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    },
    handleSaveDraft() {
      this.$refs.CustomDrawer.openLoading()
      sessionStorage.setItem('NEIGHBORHOOD', JSON.stringify({
        formModel: this.formModel
      }))
      this.$message.success('草稿保存成功')
      this.$refs.CustomDrawer.handleClose()
    },
    getFileDetail(ids) {
      if (!ids) {
        return
      }
      ids = ids.split(',')
      if (ids.length === 0) {
        return
      }
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.photoUploadLists.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
